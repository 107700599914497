import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { database } from '../../firebaseApp'
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  doc,
  getCountFromServer,
} from 'firebase/firestore'

const db = getFirestore()

export interface IMention {
  id: string
  sub?: {
    archived: boolean
    status: string
    replies: { reply: string } | string
    reply_source?: string
    relevancyScore?: number
  }
  platformContentId: string
  platform: string
  user: string
  contentTitle: string
  contentText: string
  lastUpdatedAt: Date
  contentPostedAt: Date
  createdAt: { seconds: number; nanoseconds: number }
  contentMedia: {
    mediaType: string
    mediaUrl: string
  }
  keywords: string[]
  fbPostLink?: string
}

export const fetchMentions = createAsyncThunk(
  'mentions/fetchMentions',
  async (data: { projectId: string; keywords: string[] }, thunkAPI) => {
    const { projectId, keywords } = data
    const { dispatch, getState } = thunkAPI
    let allKeywordsMentions: any[] = []

    try {
      if (keywords && keywords.length > 0) {
        console.log('Fetching mentions for keywords:', keywords)
        const filters = [
          where('keywords', 'array-contains-any', keywords),
          orderBy('createdAt', 'desc'),
        ]

        const allMentionsRefs = collection(db, 'mentions')

        const allMentionsQuery = query(allMentionsRefs, ...filters)

        const countMentions = query(
          allMentionsRefs,
          where('keywords', 'array-contains-any', keywords),
        )

        const getMentionsCount = await getCountFromServer(countMentions)
        console.log('getMentionsCount:', getMentionsCount.data().count)

        const unsubscribe = new Promise<void>((resolve, reject) => {
          const snapshotUnsubscribe = onSnapshot(
            allMentionsQuery,
            async (snapshot) => {
              console.log('Snapshot received:', snapshot.size)
              const newMentions: any[] = []

              const stateMentions = (
                getState() as { mentions: { allMentions: any[] } }
              ).mentions.allMentions

              for (const change of snapshot.docChanges()) {
                const docData = change.doc

                const subDocRef = doc(
                  db,
                  'mentions',
                  docData.id,
                  'projects',
                  projectId,
                )
                // listen to subCollection
                const subUnsubscribe = new Promise<void>((resolve, reject) => {
                  const subSnapShot = onSnapshot(
                    subDocRef,
                    async (subSnapshot) => {
                      const subData = subSnapshot.exists()
                        ? subSnapshot.data()
                        : {}
                      const mentionData = {
                        ...docData.data(),
                        sub: subData,
                        id: docData.id,
                      }

                      if (change.type === 'added') {
                        let newStateMentions = []
                        if (stateMentions.length === 0) {
                          newStateMentions = (
                            getState() as { mentions: { allMentions: any[] } }
                          ).mentions.allMentions
                        }
                        const existingIndex = newStateMentions.findIndex(
                          (mention) => mention.id === mentionData.id,
                        )
                        if (
                          existingIndex !== -1 &&
                          newStateMentions.length > 0
                        ) {
                          let mentions = [...newStateMentions]
                          mentions = [
                            ...mentions.slice(0, existingIndex),
                            mentionData,
                            ...mentions.slice(existingIndex + 1),
                          ]
                          dispatch(setAllMentions([...mentions]))
                        } else if (stateMentions.length > 0) {
                          let mentions = [...stateMentions]
                          mentions.unshift(mentionData)
                          allKeywordsMentions = [...mentions]
                        } else {
                          newMentions.push(mentionData)
                          allKeywordsMentions = [
                            ...newMentions,
                            ...newStateMentions,
                          ]
                        }
                      } else if (change.type === 'removed') {
                        const existingIndex = stateMentions.findIndex(
                          (mention) => mention.id === docData.id,
                        )
                        if (existingIndex !== -1) {
                          let mentions = [...stateMentions]
                          mentions.splice(existingIndex, 1)
                          allKeywordsMentions = [...mentions]
                        }
                      }
                      resolve()
                    },
                    reject,
                  )
                  return subSnapShot
                })
                await subUnsubscribe
              }
              if (allKeywordsMentions.length > 0) {
                console.log('All mentions fetched:', allKeywordsMentions.length)
              }
              dispatch(setAllMentions([...allKeywordsMentions]))

              resolve() // Resolve the promise when the first snapshot is processed
            },
            reject,
          ) // Reject the promise if there's an error in onSnapshot

          return snapshotUnsubscribe // Return the unsubscribe function
        })

        await unsubscribe // Wait for the initial data to be fetched
      } else {
        console.log('No keywords to fetch mentions')
        dispatch(setAllMentions([]))
      }
    } catch (error) {
      console.error('Error fetching mentions:', error)
      throw error
    }
  },
)

export const updateMentionsById = createAsyncThunk(
  'mentions/updateMentionsById',
  async (data: { id: string; projectId: string }) => {
    try {
      const { id, projectId } = data
      const mention = database.collection('mentions').doc(id)
      const mentionSnapshot = await mention.get()
      if (mentionSnapshot.exists) {
        const project = mention.collection('projects').doc(projectId)
        await project.update({ archived: true })
      } else {
        throw new Error('Mention does not exist')
      }
    } catch (error) {
      console.log('Error updating mention by id:', error)
      throw error
    }
  },
)

interface IMentionState {
  allMentions: IMention[]
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null | undefined
  allMentionLoading: 'idle' | 'pending' | 'succeeded' | 'failed'
  allMentionError: string | null | undefined
  mention: IMention | {}
}

const initialState: IMentionState = {
  allMentions: [],
  loading: 'idle',
  error: null,
  allMentionLoading: 'idle',
  allMentionError: null,
  mention: {},
}
const mentionSlice = createSlice({
  name: 'mentions',
  initialState,
  reducers: {
    setAllMentions: (state, action) => {
      state.allMentions = action.payload
    },
    setAllMentionLoading: (state, action) => {
      state.allMentionLoading = action.payload
    },
    setMention: (state, action) => {
      state.mention = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMentions.pending, (state) => {
      state.allMentionLoading = 'pending'
    })
    builder.addCase(fetchMentions.fulfilled, (state) => {
      state.allMentionLoading = 'succeeded'
    })
    builder.addCase(fetchMentions.rejected, (state, action) => {
      state.error = 'failed'
      state.error = action.error.message
    })
    builder.addCase(updateMentionsById.pending, (state) => {
      state.loading = 'pending'
    })
    builder.addCase(updateMentionsById.fulfilled, (state) => {
      state.loading = 'succeeded'
    })
    builder.addCase(updateMentionsById.rejected, (state, action) => {
      state.loading = 'failed'
      state.error = action.error.message
    })
  },
})

export const { setAllMentions, setAllMentionLoading, setMention } =
  mentionSlice.actions
export default mentionSlice.reducer
