import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Typography from '../components/Typography/Typography'
import { Chip } from '@material-tailwind/react'
import { Input, Button } from '@material-tailwind/react'
import { SocialCard } from '../components/SocialReplies/Card'
import SearchIcon from '../icons/search.svg'
import { Selector } from '../components/SocialReplies/Select'
import InstagramIcon from '@material-ui/icons/Instagram'
import { Card } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import facebook from '../icons/facebook.svg'
import twitter from '../icons/twitter.svg'
import youtube from '../icons/youtube.svg'
import linkedin from '../icons/linkedin.svg'
import pinterest from '../icons/pinterest.svg'
import tiktok from '../icons/tiktok.svg'
import refreshIcon from '../icons/refresh-icon.svg'
import navigateIcon from '../icons/navigateIcon.svg'
import { CheckCircle } from '@material-ui/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import firebase from 'firebase/compat/app'
import { useAppDispatch, useAppSelector } from '../store/store'
import redditIcon from '../icons/reddit.svg'
import replyIcon from '../icons/replyIcon.svg'
import '../css/social-replies.css'
import {
  fetchMentions,
  setAllMentionLoading,
  setAllMentions,
  updateMentionsById,
} from '../store/features/mentionSlice'
import {
  removeKeyword,
  addNegativeKeyword,
  removeNegativeKeyword,
  setCurrentProject,
} from '../store/features/projectSlice'
import CustomTooltip from '../components/Tooltip/Tooltip'
import Toast from '../components/Toast/Toast'
import { IMention } from '../store/features/mentionSlice'
import SocialTabs from '../components/SocialReplies/SocialTabs'
import { getCustomTokenFromServer } from '../utils/getCustomTokenFromServer'
import { customEvent } from '../utils/customHooks'
import { setShowUpgradeModal } from '../store/features/userSlice'
import { fetchAllAccounts } from '../store/features/accountSlice'
import { getCookieByKey } from '../utils/getCookieByKey'
import CustomIconButton from '../components/IconButton/IconButton'
import { autoGenerateReply } from '../utils/autoGenerateReply'
import CustomMenu from '../components/CustomMenu'
import { FilterBarsIcon } from '../components/SvgIcons'
import SplashScreen from '../components/SplashScreen'
import { capitalizeFirstLetterOfEachWord } from '../utils/capitalizeFirstLetterOfEachWord'
import { filterOptions, isSameDay } from '../utils/dateFiltersConstant'

interface SocialButtonsState {
  [key: string]: boolean
}

type SocialPlatform = 'reddit' | 'twitter' | 'facebook' | 'linkedin'

const SocialReplies = (): JSX.Element => {
  const [filter, setFilter] = useState('All Time')
  const [search, setSearch] = useState('')
  const [activeTab, setActiveTab] = useState('all_mentions')
  const { allMentions, allMentionLoading } = useAppSelector(
    (store) => store.mentions,
  )
  const { currentProject, loading } = useAppSelector((store) => store.project)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { userStatus, user } = useAppSelector((store) => store.user)
  const { plans } = useAppSelector((store) => store.plan)

  const hasAutoReply = currentAccount?.features?.hasAutoReply

  const allowedKeywords = useMemo(() => {
    if (plans.length > 0 && userStatus) {
      const userPlan = plans.find(
        (plan) => plan?.planID === userStatus?.plan?.product,
      )
      const allowedKeywords = parseInt(
        userPlan?.stripe_metadata_socialMonitoringAllowedKeywords || '1',
        10,
      )
      return allowedKeywords
    }
    return 1
  }, [plans, userStatus])

  const isFreePlan = useMemo(() => {
    if (plans.length > 0 && userStatus) {
      const userPlan = plans.find(
        (plan) => plan?.planID === userStatus?.plan?.product,
      )
      return userPlan?.stripe_metadata_isFreePlan !== 'free'
    }
    return true
  }, [plans, userStatus])

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [keyword, setKeyword] = useState('')
  const [reply, setReply] = useState(false)
  const [negativeKeywords, setnegativeKeywords] = useState('')
  const [keywordChip, setkeywordChip] = useState<string[]>([])
  const [keywordLoading, setKeywordLoading] = useState<boolean>(false)
  const [negativeLoading, setNegativeLoading] = useState<boolean>(false)
  const [negativeKeywordsChip, setnegativeKeywordsChip] = useState<string[]>([])
  // const [checkedItems, setCheckedItems] = useState<string[]>([])
  const [allMentionsDocs, setAllMentionsDocs] = useState<IMention[]>([])
  const [lastWeekMention, setLastWeekMention] = useState(0)
  const [socialButtonState, setSocialButtonState] =
    useState<SocialButtonsState>({
      reddit: true,
      facebook: true,
      twitter: true,
      linkedin: false,
    })

  const handleUpgrade = (): void => {
    customEvent('billing_upgrade_modal_opened', {
      category: 'accounts',
      accountId: currentAccount.id,
      user_userId: user.uid,
      projectId: currentProject?.id,
      actionSource: 'projects-limit-reached',
    })
    dispatch(setShowUpgradeModal(true))
  }

  const [syncLoading, setSyncLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [setDisabled, setSetDisabled] = useState(false)

  const handlePlatformFilter = (platform: string): void => {
    setSocialButtonState((prevState) => ({
      ...prevState,
      [platform]: prevState[platform] ? false : true,
    }))
    const socialState = {
      reddit:
        platform === 'reddit'
          ? !socialButtonState.reddit
          : socialButtonState.reddit,
      twitter:
        platform === 'twitter'
          ? !socialButtonState.twitter
          : socialButtonState.twitter,
      facebook:
        platform === 'facebook'
          ? !socialButtonState.facebook
          : socialButtonState.facebook,
      linkedin:
        platform === 'linkedin'
          ? !socialButtonState.linkedin
          : socialButtonState.linkedin,
    }
    const filterDocs = (): void => {
      const platforms = Object.keys(socialState).filter(
        (platform) => socialState[platform as SocialPlatform],
      )
      const filteredDocs = allMentions.filter((item) =>
        platforms.includes(item.platform),
      )

      const oneDayInMs = 24 * 60 * 60 * 1000

      const today = new Date()
      const yesterday = new Date(today.getTime() - oneDayInMs)
      const lastWeek = new Date(today.getTime() - 7 * oneDayInMs)
      const last30Days = new Date(today.getTime() - 30 * oneDayInMs)
      const last90Days = new Date(today.getTime() - 90 * oneDayInMs)

      switch (filter) {
        case 'All Time':
          setAllMentionsDocs(filteredDocs)
          break
        case 'Today':
          setAllMentionsDocs(
            filteredDocs.filter((item: IMention) =>
              isSameDay(today, item.createdAt.seconds * 1000),
            ),
          )
          break
        case 'Yesterday':
          setAllMentionsDocs(
            filteredDocs.filter((item: IMention) =>
              isSameDay(yesterday, item.createdAt.seconds * 1000),
            ),
          )
          break
        case 'Last 7 days':
          setAllMentionsDocs(
            filteredDocs.filter(
              (item: IMention) =>
                item.createdAt.seconds * 1000 >= lastWeek.getTime(),
            ),
          )
          break
        case 'Last 30 days':
          setAllMentionsDocs(
            filteredDocs.filter(
              (item) => item.createdAt.seconds * 1000 >= last30Days.getTime(),
            ),
          )
          break
        case 'Last 90 days':
          setAllMentionsDocs(
            filteredDocs.filter(
              (item) => item.createdAt.seconds * 1000 >= last90Days.getTime(),
            ),
          )
          break
        default:
          break
      }
    }
    filterDocs()
  }

  const _getLastWeekCount = useCallback(() => {
    const oneWeekInMs = 7 * 24 * 60 * 60 * 1000
    const currentTime = Date.now()

    const lastWeekData = allMentions.filter((item) => {
      const itemTime =
        item.createdAt.seconds * 1000 + item.createdAt.nanoseconds / 1000000
      return currentTime - itemTime <= oneWeekInMs
    })
    setLastWeekMention(lastWeekData?.length)
  }, [allMentions])

  useEffect(() => {
    if (allMentions?.length) {
      setAllMentionsDocs(
        allMentions.filter((item: IMention) =>
          Object.entries(socialButtonState).some(
            ([platform, isEnabled]) => isEnabled && item.platform === platform,
          ),
        ),
      )
      _getLastWeekCount()
    } else {
      setLastWeekMention(0)
    }
  }, [_getLastWeekCount, allMentions, socialButtonState])

  useEffect(() => {
    const inviteId = localStorage.getItem('inviteId')
    const inviteValidityCode = localStorage.getItem('inviteValidityCode')

    const acceptInviteAndProceed = async (): Promise<void> => {
      const token = await firebase.auth().currentUser?.getIdToken()
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_API}/accounts/acceptInvite`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            data: {
              inviteId,
              validityCode: parseInt(inviteValidityCode!, 10),
            },
          }),
        },
      )
      const data = await response.json()
      if (data && data.status === 'success') {
        Toast({
          title: t('accountSettings.inviteAccepted'),
          text: t('accountSettings.accountJoined'),
          variant: 'success',
          options: { autoClose: 5000 },
        })
        localStorage.removeItem('inviteId')
        localStorage.removeItem('inviteValidityCode')
        dispatch(fetchAllAccounts())
      }
    }

    if (inviteId && inviteValidityCode && user) {
      acceptInviteAndProceed()
    }
  }, [user])

  const addKeyword = useCallback(async (): Promise<void> => {
    try {
      Toast({
        title: 'Please wait',
        text: 'Fetching mentions based on keyword',
        variant: 'success',
        options: { autoClose: 5000 },
      })
      setKeywordLoading(true)
      Toast({
        // title: t && t('socialReplies.toast.title'),
        // text: t && t('socialReplies.toast.text'),
        title: 'Fetching Posts',
        text: 'Please wait while we fetch the posts for the keyword. This may take a few minutes.',
        variant: 'info',
      })
      const payload = {
        keyword: keyword,
        platform: ['reddit', 'twitter', 'facebook'],
        projectId: currentProject.id,
      }
      const token = await firebase.auth().currentUser?.getIdToken()
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_API}/fetchPosts`,
        // `http://127.0.0.1:5001/well-shared-development/us-central1/api/fetchPosts`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        },
      )
      if (response.status === 200) {
        customEvent('monitor_keyword_added', {
          category: 'social_monitoring',
          accountId: currentProject?.accountId,
          projectId: currentProject?.id,
          user_userId: user.uid,
          keyword: keyword,
          actionMetadata: 'positive-keyword',
        })
        setKeyword('')
        setkeywordChip((prevKeywords) => [...prevKeywords, keyword])

        dispatch(
          setCurrentProject({
            ...currentProject,
            keywords: [...currentProject.keywords, keyword],
          }),
        )
        dispatch(setAllMentionLoading('idle'))
        setKeywordLoading(false)
        Toast({
          title: t && t('socialReplies.toast.title'),
          text: t && t('socialReplies.toast.text'),
          variant: 'success',
        })
      }
    } catch (error) {
      setKeywordLoading(false)
      console.error('error', error)
    }
  }, [currentProject, dispatch, keyword, t, user.uid])

  const handleSync = useCallback(async (): Promise<void> => {
    setSyncLoading(true)
    try {
      const payload = {
        platform: ['reddit', 'twitter', 'facebook'],
        account: [currentAccount.id],
      }
      const token = await firebase.auth().currentUser?.getIdToken()
      const response = await fetch(
        // `http://127.0.0.1:5001/well-shared-development/us-central1/api/updatePosts`,
        `${process.env.REACT_APP_FIREBASE_API}/updatePosts`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        },
      )
      const data = await response.text()
      console.log('Response:', data)

      if (response.status === 200) {
        console.log('Mentions Synced', currentProject)

        dispatch(setAllMentionLoading('idle'))
        dispatch(
          fetchMentions({
            projectId: currentProject.id,
            keywords: currentProject.keywords,
          }),
        )
        setIsDisabled(true)
        setSetDisabled(true)
        const currentMilliseconds = Date.now()
        const currentSeconds = Math.floor(currentMilliseconds / 1000)
        const seconds = currentSeconds.toString().padStart(10, '0')
        const performanceTime = performance.now()
        const currentNanoseconds = Math.floor(performanceTime * 1000000)
        const nanoseconds = Math.floor(currentNanoseconds % 1000000000)
        const nano = nanoseconds.toString().padStart(9, '0')

        const time = {
          seconds: parseInt(seconds, 10),
          nanoseconds: parseInt(nano, 10),
        }

        dispatch(
          setCurrentProject({
            ...currentProject,
            lastDataFetchedAt: time,
          }),
        )
      }

      setSyncLoading(false)

      customEvent('mentions_sync_data_manually', {
        projectId: currentProject?.id,
        accountId: currentProject?.accountId,
        user_userId: user.uid,
      })
    } catch (error) {
      console.error('error', error)
      setSyncLoading(false)
    }
  }, [currentAccount, currentProject, dispatch, setCurrentProject])

  useEffect(() => {
    if (currentProject?.id) {
      console.log('fetching mentions')
      const data = {
        projectId: currentProject.id,
        keywords: currentProject.keywords,
      }
      dispatch(setAllMentions([]))
      setTimeout(() => {
        dispatch(fetchMentions(data))
      }, 2000)
    }
  }, [currentProject.keywords])

  useEffect(() => {
    if (currentProject && loading !== 'idle') {
      if (currentProject.lastDataFetchedAt && !setDisabled) {
        const lastDataFetchedAt = new Date(
          currentProject.lastDataFetchedAt.seconds * 1000,
        )
        const currentDate = new Date()
        const timeDifference = Math.floor(
          (currentDate.getTime() - (lastDataFetchedAt?.getTime() || 0)) /
            (1000 * 60),
        )
        if (timeDifference < 24 * 60) {
          setIsDisabled(true)
        } else {
          setIsDisabled(false)
        }
      } else if (!setDisabled) {
        setIsDisabled(false)
      }
    }
    if (keywordChip?.length === 0) {
      setIsDisabled(true)
    }
  }, [currentProject, isDisabled, keywordChip?.length, loading, setDisabled])

  const _checkKeywords = (keywordsArr: string[], value: string): boolean => {
    const lowerCaseKeywords = keywordsArr.map((item) => item.toLowerCase())
    const lowerCaseValue = value.toLowerCase()
    return lowerCaseKeywords.includes(lowerCaseValue)
  }

  useEffect(() => {
    const sendDataToChrome = async (): Promise<void> => {
      const token = await getCustomTokenFromServer()
      window.postMessage(
        {
          token: token,
          accountId: currentProject.accountId,
          projectId: currentProject.id,
        },
        '*',
      )
      if (document?.cookie) {
        const ga4ClientId = getCookieByKey('_ga')
        if (ga4ClientId) {
          const parts = ga4ClientId?.split('.')
          const extractedString = parts?.slice(2).join('.')
          window.postMessage({ ga4ClientId: extractedString }, '*')
        }
      }
    }
    sendDataToChrome()
  }, [currentProject.accountId, currentProject.id])
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target?.value?.toLowerCase()
    setSearch(event.target?.value)
    if (value?.length > 3) {
      const filteredMention = allMentions?.filter((item) => {
        return (
          item.contentTitle?.toLocaleLowerCase()?.includes(value) ||
          item.contentText?.toLocaleLowerCase()?.includes(value) ||
          item.user?.toLocaleLowerCase()?.includes(value) ||
          _checkKeywords(item.keywords, value)
        )
      })
      const filteredDocs = filteredMention.filter(
        (item) => socialButtonState[item.platform as SocialPlatform],
      )
      setAllMentionsDocs(filteredDocs)
    } else {
      const filteredDocs = allMentions.filter(
        (item) => socialButtonState[item.platform as SocialPlatform],
      )
      setAllMentionsDocs(filteredDocs)
    }
  }

  useEffect(() => {
    if (Object.keys(currentProject).length > 0) {
      setkeywordChip(currentProject.keywords ? currentProject.keywords : [])
    }
  }, [currentProject])

  useEffect(() => {
    if (Object.keys(currentProject).length > 0) {
      setnegativeKeywordsChip(
        currentProject.negativeKeywords ? currentProject.negativeKeywords : [],
      )
    }
  }, [currentProject])

  const handleFilterChange = (value: string | undefined): void => {
    if (value) {
      setFilter(value)

      const filteredDocs = allMentions.filter(
        (item) => socialButtonState[item.platform as SocialPlatform],
      )

      const oneDayInMs = 24 * 60 * 60 * 1000

      const today = new Date()
      const yesterday = new Date(today.getTime() - oneDayInMs)
      const lastWeek = new Date(today.getTime() - 7 * oneDayInMs)
      const last30Days = new Date(today.getTime() - 30 * oneDayInMs)
      const last90Days = new Date(today.getTime() - 90 * oneDayInMs)

      switch (value) {
        case 'All Time':
          setAllMentionsDocs(
            allMentions.filter((item: IMention) => {
              return Object.entries(socialButtonState).some(
                ([platform, isEnabled]) =>
                  isEnabled && item.platform === platform,
              )
            }),
          )
          break
        case 'Today':
          setAllMentionsDocs(
            allMentions.filter((item: IMention) => {
              return (
                isSameDay(today, item.createdAt.seconds * 1000) &&
                Object.entries(socialButtonState).some(
                  ([platform, isEnabled]) =>
                    isEnabled && item.platform === platform,
                )
              )
            }),
          )
          break
        case 'Yesterday':
          setAllMentionsDocs(
            allMentions.filter(
              (item: IMention) =>
                isSameDay(yesterday, item.createdAt.seconds * 1000) &&
                Object.entries(socialButtonState).some(
                  ([platform, isEnabled]) =>
                    isEnabled && item.platform === platform,
                ),
            ),
          )
          break
        case 'Last 7 days':
          setAllMentionsDocs(
            allMentions.filter(
              (item: IMention) =>
                item.createdAt.seconds * 1000 >= lastWeek.getTime() &&
                Object.entries(socialButtonState).some(
                  ([platform, isEnabled]) =>
                    isEnabled && item.platform === platform,
                ),
            ),
          )
          break
        case 'Last 30 days':
          setAllMentionsDocs(
            allMentions.filter(
              (item) =>
                item.createdAt.seconds * 1000 >= last30Days.getTime() &&
                Object.entries(socialButtonState).some(
                  ([platform, isEnabled]) =>
                    isEnabled && item.platform === platform,
                ),
            ),
          )
          break
        case 'Last 90 days':
          setAllMentionsDocs(
            allMentions.filter(
              (item) =>
                item.createdAt.seconds * 1000 >= last90Days.getTime() &&
                Object.entries(socialButtonState).some(
                  ([platform, isEnabled]) =>
                    isEnabled && item.platform === platform,
                ),
            ),
          )
          break
        default:
          setAllMentionsDocs(filteredDocs)
          break
      }
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setKeyword(event.target.value)
  }

  const onChangeNegativeKeywords = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setnegativeKeywords(event.target.value)
  }

  const handleClose = async (itemToRemove: string): Promise<void> => {
    await removeKeyword(currentProject.id, itemToRemove)
    setkeywordChip(keywordChip?.filter((item) => item !== itemToRemove))
  }

  const handleCloseNegative = async (itemToRemove: string): Promise<void> => {
    await removeNegativeKeyword(currentProject.id, itemToRemove)
    setnegativeKeywordsChip(
      negativeKeywordsChip.filter((item) => item !== itemToRemove),
    )
  }

  const handleAddNegativeKeyword = async (itemToAdd: string): Promise<void> => {
    customEvent('monitor_keyword_added', {
      category: 'social_monitoring',
      accountId: currentProject?.accountId,
      projectId: currentProject?.id,
      user_userId: user.uid,
      keyword: itemToAdd,
      actionMetadata: 'negative-keyword',
    })

    setNegativeLoading(true)
    await addNegativeKeyword(currentProject.id, itemToAdd)
    setnegativeKeywords('')
    setnegativeKeywordsChip((prevKeywords) => [...prevKeywords, itemToAdd])
    setNegativeLoading(false)
  }

  const REPLIES_COLUMNS = [
    {
      title: 'Site',
      field: 'name',
      width: '5%',
    },
    {
      title: 'Mention',
      field: 'Mention',
      width: '25%',
    },
    {
      title: t && t('transcripts.keywords'),
      field: 'keywords',
      width: '15%',
    },
    {
      title: t && t('transcripts.status'),
      field: 'status',
      width: '15%',
    },
    {
      title: 'Date',
      field: 'Date',
      width: '20%',
    },
    {
      title: 'Actions',
      field: 'Actions',
      width: '10%',
    },
  ]

  if (hasAutoReply) {
    REPLIES_COLUMNS.push({
      title: 'Reply',
      field: 'reply',
      width: '10%',
    })
  }

  const handleNavigate = (
    url: string,
    platform?: string,
    userId?: string,
  ): void => {
    customEvent('mention_opened', {
      category: 'social_monitoring',
      accountId: currentProject?.accountId,
      projectId: currentProject?.id,
      user_userId: user.uid,
      mentionId: url,
      actionMetadata: 'within-web-app',
    })

    if (platform === 'reddit') {
      window.postMessage(
        { webRedirect: true, projectId: currentProject?.id, mentionId: url },
        '*',
      )
      window.open(`https://www.reddit.com/${url}`, '_blank')
    } else if (platform === 'twitter') {
      window.postMessage(
        { webRedirect: true, projectId: currentProject?.id, mentionId: url },
        '*',
      )
      window.open(`https://twitter.com/${userId}/status/${url}`, '_blank')
    } else if (platform === 'facebook') {
      window.postMessage(
        { webRedirect: true, projectId: currentProject?.id, mentionId: url },
        '*',
      )
      window.open(`https://facebook.com${url}`, '_blank')
    } else {
      window.open(url, '_blank')
    }
  }

  const renderStatusIcon = useCallback((status: string) => {
    switch (status) {
      case 'reddit':
        return (
          <div className="rounded-full w-6 h-6 bg-black">
            <img src={redditIcon} alt="" />
          </div>
        )
      case 'twitter':
        return (
          <div className="rounded-full w-6 h-6 bg-black flex justify-center items-center">
            <img src={twitter} alt="" />
          </div>
        )
      case 'facebook':
        return (
          <div className="rounded-full w-6 h-6 bg-black">
            <img src={facebook} alt="" />
          </div>
        )
      case 'linkedin':
        return (
          <div className="rounded-full w-6 h-6 bg-black">
            <img src={linkedin} alt="" />
          </div>
        )
      case 'youtube':
        return (
          <div className="rounded-full w-6 h-6 bg-black">
            <img src={youtube} alt="" />
          </div>
        )
      case 'pinterest':
        return (
          <div className="rounded-full w-6 h-6 bg-black">
            <img src={pinterest} alt="" />
          </div>
        )
      case 'instagram':
        return (
          <div className="rounded-full w-8 h-8 bg-gradient-to-tr from-[#6b4ae7] via-[#f33d9c] to-orange-500 instaIcon">
            <InstagramIcon className="text-white mx-auto" />
          </div>
        )
      case 'tiktok':
        return (
          <div className="rounded-full w-6 h-6 bg-black">
            <img src={tiktok} alt="" />
          </div>
        )
      default:
        return null
    }
  }, [])

  // const changeCheckStatusData = async (item: any): Promise<void> => {
  //   const isChecked = checkedItems.some(
  //     (checkedItem: any) => checkedItem.id === item.id,
  //   )

  //   if (isChecked) {
  //     await setCheckedItems((prev) => prev.filter((v: any) => v.id !== item.id))
  //     // setSelectAllChecked(false)
  //   } else {
  //     await setCheckedItems((prev) => [...prev, item])
  //   }
  // }

  const handleKeywordsTab = useCallback(() => {
    const element: HTMLElement | null = document.querySelector(
      'li[data-value="keywords"]',
    )
    element?.click()
  }, [])

  const handleTooltipContent = useCallback(
    (platform: string): string => {
      const isActive = socialButtonState[platform]
      const socialPlatform =
        platform.charAt(0).toLocaleUpperCase() + platform.slice(1)
      return `${isActive ? 'Hide ' : 'Show '} ${socialPlatform} mentions`
    },
    [socialButtonState],
  )

  // const _getDateTime = () => {
  //   const timestamp = currentProject.lastDataFetchedAt
  //   if (timestamp) {
  //     const date = new firebase.firestore.Timestamp(
  //       timestamp.seconds,
  //       timestamp.nanoseconds,
  //     ).toDate()

  //     const day = date.getDate()
  //     const month = date.toLocaleString('en-US', { month: 'short' })
  //     const year = date.getFullYear()
  //     const hour = date.getHours()
  //     const minute = date.getMinutes()
  //     const ampm = hour >= 12 ? 'pm' : 'am'
  //     const formattedHour = hour % 12 === 0 ? 12 : hour % 12

  //     return `${day} ${formattedHour}:${minute
  //       .toString()
  //       .padStart(2, '0')}${ampm} ${month} ${year}`
  //   }
  //   return ''
  // }

  const handleMenuItem = useCallback(
    async (id: string): Promise<void> => {
      Toast({
        title: t && t('socialReplies.mentions.hideToast.title'),
        text: t && t('socialReplies.mentions.hideToast.text'),
        variant: 'success',
      })
      const data = {
        id,
        projectId: currentProject.id,
      }
      dispatch(updateMentionsById(data))
      customEvent('mention_hidden', {
        accountId: currentAccount.id,
        projectId: currentProject.id,
        mentionId: id,
        user_userId: user.uid,
      })
    },
    [allMentions, currentAccount.id, currentProject.id, dispatch, user.uid],
  )

  const data = [
    {
      label: 'All mentions',
      value: 'all_mentions',
      desc: (
        <>
          <div className="w-[400px] sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-1 pb-8">
            {/* Social Cards */}
            <div className="flex gap-2">
              <SocialCard
                title="Mentions"
                totalMentions={allMentions?.length}
                lastWeek={`Last Week ${lastWeekMention ? lastWeekMention : 0}`}
              />
              <SocialCard
                title="PRO HINT"
                description="Set up Reddit monitoring"
              />
              <SocialCard
                title="AUTOPILOT MODE"
                description="Set up Auto Replies"
              />
              <SocialCard
                title="CHROME EXTENSION"
                description="Write replies, in 1 click"
              />
            </div>
            {/* Search, Filter and Icons */}
            <div className="mt-6 flex justify-between">
              <div className="flex gap-2 grow">
                <div className="relative w-80 rounded-xl">
                  <img
                    src={SearchIcon}
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                  />
                  <Input
                    type="text"
                    size="md"
                    placeholder="Search"
                    value={search}
                    className="pl-10 !border-lstnBlueGray-100 focus:!border-gray-900 rounded-xl text-blue-gray-400 placeholder:opacity-100"
                    onChange={onSearch}
                    labelProps={{
                      className: 'hidden',
                    }}
                    crossOrigin={undefined}
                  />
                </div>
                <Button
                  placeholder={undefined}
                  className="bg-transparent outline-none shadow-none hover:shadow-none px-3"
                >
                  <FilterBarsIcon />
                </Button>

                <Selector
                  options={filterOptions}
                  selectedValue={filter}
                  onChange={handleFilterChange}
                  className="relative text-sm font-medium w-24 border-none h-[1.5rem] filterSelector text-blue-gray-500  hover:text-lstnBlueGray-900 uppercase bg-transparent rounded-lg"
                  children={undefined}
                />
              </div>
              <div className="flex items-center gap-2">
                {isFreePlan && (
                  <>
                    <Typography
                      variant="small"
                      className="font-normal text-lstnBlueGray-500 text-xs w-[131px]"
                    >
                      Data last Updated at:{' '}
                      {currentProject.lastDataFetchedAt &&
                        new firebase.firestore.Timestamp(
                          currentProject.lastDataFetchedAt.seconds,
                          currentProject.lastDataFetchedAt.nanoseconds,
                        )
                          .toDate()
                          .toLocaleString('en-US', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true,
                          })}
                    </Typography>
                    <CustomTooltip
                      content={
                        isDisabled
                          ? t('socialReplies.refreshTooltip.disabled')
                          : t('socialReplies.refreshTooltip.enabled')
                      }
                      className="bg-lstnBlueGray-400 w-56"
                    >
                      <span>
                        <Button
                          placeholder={undefined}
                          loading={syncLoading}
                          disabled={isDisabled}
                          onClick={handleSync}
                          variant="filled"
                          size="sm"
                          className="bg-well_primary-500 text-white capitalize flex"
                        >
                          <img
                            src={refreshIcon}
                            alt="DirectoriesListingLogo"
                            className="w-4 h-4 mr-2"
                          />
                          REFRESH
                        </Button>
                      </span>
                    </CustomTooltip>
                  </>
                )}
                <CustomTooltip
                  content={handleTooltipContent('reddit')}
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <CustomIconButton
                      className={`rounded-full w-8 h-8 bg-[#FF4500] ${
                        !socialButtonState.reddit
                          ? 'focus:shadow-md active:opacity-[0.4] focus:opacity-[0.4] opacity-40 shadow-none'
                          : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                      }`}
                      onClick={() => handlePlatformFilter('reddit')}
                    >
                      <img src={redditIcon} alt="" />
                    </CustomIconButton>
                  </span>
                </CustomTooltip>

                <CustomTooltip
                  content={handleTooltipContent('twitter')}
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <CustomIconButton
                      className={`rounded-full w-8 h-8 bg-black ${
                        !socialButtonState.twitter
                          ? 'focus:shadow-md active:opacity-[0.4] focus:opacity-[0.4] opacity-40 shadow-none'
                          : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                      }`}
                      onClick={() => handlePlatformFilter('twitter')}
                    >
                      <img src={twitter} alt="" />
                    </CustomIconButton>
                  </span>
                </CustomTooltip>

                <CustomTooltip
                  content={handleTooltipContent('facebook')}
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <CustomIconButton
                      // disabled={socialButtonState !== 'facebook'}
                      className={`rounded-full w-8 h-8 bg-[#35518D] ${
                        !socialButtonState.facebook
                          ? 'focus:shadow-md active:opacity-[0.4] focus:opacity-[0.4] opacity-40 shadow-none'
                          : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                      }`}
                      onClick={() => handlePlatformFilter('facebook')}
                    >
                      <img src={facebook} alt="" />
                    </CustomIconButton>
                  </span>
                </CustomTooltip>

                {/* <CustomTooltip
                  content="coming soon"
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <IconButton
                      disabled
                      placeholder={undefined}
                      className="rounded-full w-8 h-8 bg-gradient-to-tr from-[#6b4ae7] via-[#f33d9c] to-orange-500 instaIcon"
                      onClick={() =>
                        handleNavigate('https://www.instagram.com')
                      }
                    >
                      <InstagramIcon className="text-white mx-auto" />
                    </IconButton>
                  </span>
                </CustomTooltip> */}

                {/* <CustomTooltip
                  content="coming soon"
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <IconButton
                      disabled
                      placeholder={undefined}
                      className="rounded-full w-8 h-8 bg-[#E60019]"
                      onClick={() =>
                        handleNavigate('https://www.pinterest.com/')
                      }
                    >
                      <img src={pinterest} alt="" />
                    </IconButton>
                  </span>
                </CustomTooltip>

                <CustomTooltip
                  content="coming soon"
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <IconButton
                      disabled
                      placeholder={undefined}
                      className="rounded-full w-8 h-8 bg-black"
                      onClick={() => handleNavigate('https://www.tiktok.com/')}
                    >
                      <img src={tiktok} alt="" />
                    </IconButton>
                  </span>
                </CustomTooltip> */}

                <CustomTooltip
                  content={handleTooltipContent('linkedin')}
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <CustomIconButton
                      className={`rounded-full w-8 h-8 bg-[#0A66C2] ${
                        !socialButtonState.linkedin
                          ? 'focus:shadow-md active:opacity-[0.4] focus:opacity-[0.4] opacity-40 shadow-none'
                          : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                      }`}
                      onClick={() => handlePlatformFilter('linkedin')}
                    >
                      <img src={linkedin} alt="" />
                    </CustomIconButton>
                  </span>
                </CustomTooltip>

                {/* <CustomTooltip
                  content="coming soon"
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <IconButton
                      disabled
                      placeholder={undefined}
                      className="rounded-full w-8 h-8 bg-[#FF0000]"
                      onClick={() => handleNavigate('https://www.youtube.com/')}
                    >
                      <img src={youtube} alt="" />
                    </IconButton>
                  </span>
                </CustomTooltip> */}
              </div>
            </div>
            {/* Mentions Table */}
            <div>
              <Card
                className="h-full w-full mt-6 overflow-hidden border border-blue-gray-50"
                placeholder={undefined}
              >
                <table className="text-left">
                  <thead>
                    <tr>
                      <th
                        className={`border-b border-blue-gray-100 bg-[#F9FAFB] gap-2 h-10`}
                        style={{
                          width: '3%',
                        }}
                      >
                        {/* <Checkbox
                          onClick={(e) => {
                            e.stopPropagation()
                            handleSelectAll()
                          }}
                          checked={selectAllChecked}
                          crossOrigin={undefined}
                        /> */}
                      </th>
                      {REPLIES_COLUMNS.map((val, index) => (
                        <th
                          key={index}
                          className="border-b border-blue-gray-100 bg-[#F9FAFB] gap-2 h-10 text-sm text-blue-gray-900 font-semibold leading-none pr-6"
                          style={{
                            width: val.width, // Set max-width to avoid columns extending beyond container
                          }}
                        >
                          <Typography
                            variant="small"
                            className="font-medium text-lstnBlueGray-900 text-base"
                          >
                            {val.title}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {allMentionLoading === 'pending' ||
                    allMentionLoading === 'idle' ? (
                      <tr>
                        <td
                          colSpan={REPLIES_COLUMNS.length + 1}
                          className="text-lstnBlueGray-500 text-center w-full h-[350px]"
                        >
                          <div className="max-w-full animate-pulse text-center items-center flex flex-col">
                            <Typography
                              as="div"
                              variant="h1"
                              className="mb-4 h-3 w-56 rounded-full bg-gray-300"
                            >
                              &nbsp;
                            </Typography>
                            <Typography
                              as="div"
                              variant="paragraph"
                              className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                            >
                              &nbsp;
                            </Typography>
                            <Typography
                              as="div"
                              variant="paragraph"
                              className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                            >
                              &nbsp;
                            </Typography>
                            <Typography
                              as="div"
                              variant="paragraph"
                              className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                            >
                              &nbsp;
                            </Typography>
                            <Typography
                              as="div"
                              variant="paragraph"
                              className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                            >
                              &nbsp;
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    ) : !allMentions.length &&
                      allMentionLoading === 'succeeded' ? (
                      <tr>
                        <td
                          colSpan={REPLIES_COLUMNS.length + 1}
                          className="text-lstnBlueGray-500 text-center h-[350px]"
                        >
                          <div className="w-96 mx-auto">
                            <div className="flex justify-center mb-4">
                              <img src="/emptyglass.svg" alt="img" />
                            </div>
                            <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                              {t('socialReplies.table.emptyMessage')}
                            </Typography>
                            <Typography
                              variant="small"
                              className="text-base font-normal"
                            >
                              {t('socialReplies.table.content')}
                            </Typography>
                            <Typography
                              className="text-sm mt-3 font-medium text-lstnBlue-500 cursor-pointer text-well_primary-500 flex items-center justify-center"
                              onClick={handleKeywordsTab}
                            >
                              <span className="inline-block lstn-btn ml-0 w-3 h-3 p-0 rounded-full text-center relative mr-1 bg-well_primary-500">
                                <svg width="12" height="12" aria-hidden="true">
                                  <use xlinkHref="#icon-pluss" />
                                </svg>
                              </span>
                              {t('socialReplies.table.keyword')}
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    ) : allMentions.length &&
                      !allMentionsDocs.length &&
                      allMentionLoading === 'succeeded' ? (
                      <tr>
                        <td
                          colSpan={REPLIES_COLUMNS.length + 1}
                          className="text-lstnBlueGray-500 text-center h-[350px]"
                        >
                          <div className="w-96 mx-auto">
                            <div className="flex justify-center mb-4">
                              <img src="/emptyFilter.svg" alt="img" />
                            </div>
                            <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                              {t('socialReplies.table.emptyMessage')}
                            </Typography>
                            <Typography
                              variant="small"
                              className="text-base font-normal"
                            >
                              {t('socialReplies.table.filterMessage')}
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      allMentionsDocs.map((value, index) => {
                        // const isChecked = checkedItems.some(
                        //   (checkedItem: any) => checkedItem.id === index,
                        // )
                        return value.sub?.archived === false ? (
                          <tr className="border-b border-gray-300" key={index}>
                            <td>
                              {/* <Checkbox
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    changeCheckStatusData({ ...value, id: index })
                                  }}
                                  checked={isChecked}
                                  crossOrigin={undefined}
                                /> */}
                            </td>
                            <td>{renderStatusIcon(value.platform)}</td>
                            <td>
                              <div className="py-2 mr-3 w-106">
                                <Typography className="text-base text-lstnBlueGray-500 font-normal">
                                  @{value.user}
                                </Typography>
                                {value.contentTitle && (
                                  <Typography className="text-base text-lstnBlueGray-900 font-medium line-clamp-3">
                                    {value.contentTitle}
                                  </Typography>
                                )}
                                {value.contentText && (
                                  <Typography className="text-base text-lstnBlueGray-900 font-normal line-clamp-3">
                                    {value.contentText}
                                  </Typography>
                                )}
                                {value.sub?.status == 'replied' ? (
                                  <div>
                                    <div className="flex items-center">
                                      <span className="mr-1">
                                        <img src={replyIcon} alt="" />
                                      </span>
                                      <Typography className="text-lstnBlueGray-100 text-sm font-normal">
                                        Replied via{' '}
                                        {value?.sub?.reply_source ||
                                          'Chrome extension'}
                                      </Typography>
                                    </div>
                                    <div className="bg-well_primary-50 py-2 px-3 rounded-md">
                                      <Typography className="text-base text-well_primary-500 font-normal">
                                        @{currentAccount.name || 'vitomrgt'}
                                      </Typography>
                                      <Typography className="text-base text-lstnBlueGray-900 font-normal line-clamp-2">
                                        {Array.isArray(value.sub?.replies)
                                          ? value.sub?.replies[1]?.reply ||
                                            value.sub?.replies[0]
                                          : undefined}
                                      </Typography>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </td>
                            <td>
                              <div className="flex flex-wrap gap-2">
                                {value.keywords?.map((word) => {
                                  return (
                                    <Typography
                                      key={word}
                                      className="p-1 border rounded-md border-well_primary-500 text-xs text-well_primary-500 font-sm"
                                    >
                                      {word}
                                    </Typography>
                                  )
                                })}
                              </div>
                            </td>
                            <td>
                              <div className="flex gap-2 items-center">
                                {value?.sub &&
                                value.sub?.status == 'replied' ? (
                                  <CheckCircle className="text-well_primary-500 iconSize20" />
                                ) : (
                                  <CheckCircle className="text-[#CFD8DC] iconSize20" />
                                )}
                                <Typography className="text-base text-lstnBlueGray-900 font-normal">
                                  {value?.sub && value.sub?.status}
                                </Typography>
                              </div>
                            </td>
                            <td>
                              <Typography className="text-base text-lstnBlueGray-900 font-normal">
                                {new firebase.firestore.Timestamp(
                                  value.createdAt.seconds,
                                  value.createdAt.nanoseconds,
                                )
                                  .toDate()
                                  .toLocaleString('en-US', {
                                    month: 'short',
                                    day: '2-digit',
                                    year: 'numeric',
                                  })}
                              </Typography>
                            </td>
                            <td>
                              <div className="flex items-center gap-4 ml-2">
                                <CustomTooltip
                                  content="Open in New Tab"
                                  className="bg-lstnBlueGray-400"
                                >
                                  <Button
                                    placeholder={undefined}
                                    variant="text"
                                    className="min-w-0 h-auto shadow-none hover:shadow-none p-0"
                                    onClick={() =>
                                      handleNavigate(
                                        value.platform === 'facebook'
                                          ? value.fbPostLink || ''
                                          : value.platformContentId,
                                        value.platform,
                                        value.user,
                                      )
                                    }
                                  >
                                    <img src={navigateIcon} alt="Icon" />
                                  </Button>
                                </CustomTooltip>
                                <CustomMenu
                                  menuHandlerIcon={<MoreVertIcon />}
                                  menuitems={[
                                    {
                                      label:
                                        t &&
                                        t(
                                          'socialReplies.mentions.hideMenuItem',
                                        ),
                                      itemClick: async () =>
                                        await handleMenuItem(
                                          value.platformContentId,
                                        ),
                                      icon: <VisibilityOffIcon />,
                                    },
                                  ]}
                                />
                              </div>
                            </td>
                            {hasAutoReply && (
                              <td>
                                {/* Added all code for auto reply in one place if in future we want to remove it. It will be easy to comment or remove */}
                                <div className="flex items-center pr-3">
                                  <CustomTooltip
                                    content="Comment on reddit post by clicking Reply!!!"
                                    className="bg-lstnBlueGray-400"
                                  >
                                    <Button
                                      placeholder={undefined}
                                      variant="text"
                                      disabled={
                                        value?.sub?.status == 'replied' ||
                                        reply ||
                                        value.platform !== 'reddit'
                                      }
                                      className="min-w-0 h-auto shadow-none hover:shadow-none p-0 hover:bg-white"
                                      onClick={async () => {
                                        setReply(true)
                                        Toast({
                                          title: 'Replying...',
                                          text: 'Please wait...',
                                          variant: 'info',
                                        })
                                        if (
                                          value?.sub?.status == 'notReplied'
                                        ) {
                                          const response =
                                            await autoGenerateReply({
                                              accountId: currentAccount.id,
                                              projectId: currentProject.id,
                                              postId: value?.platformContentId,
                                            })
                                          if (
                                            response ===
                                            'Comment posted successfully!'
                                          ) {
                                            Toast({
                                              title:
                                                'Reply posted successfully!',
                                              // text: 'Reply posted successfully!',
                                              variant: 'success',
                                            })
                                            const mention = {
                                              projectId: currentProject.id,
                                              keywords: currentProject.keywords,
                                            }
                                            dispatch(fetchMentions(mention))
                                          } else {
                                            Toast({
                                              title: 'Reply failed!',
                                              text: response,
                                              variant: 'error',
                                            })
                                          }
                                        }
                                        setReply(false)
                                      }}
                                    >
                                      {t && t('socialReplies.mentions.reply')}
                                    </Button>
                                  </CustomTooltip>
                                </div>
                              </td>
                            )}
                          </tr>
                        ) : null
                      })
                    )}
                  </tbody>
                </table>
              </Card>
            </div>
          </div>
        </>
      ),
    },
    // {
    //   label: 'Replies',
    //   value: 'Replies',
    //   desc: (
    //     <>
    //       <div className="w-[400px] md:w-[936px] h-auto mx-auto mt-1">
    //         <Typography className="text-base font-normal text-lstnGray-900 mb-3">
    //           coming soon
    //         </Typography>
    //       </div>
    //     </>
    //   ),
    // },
    {
      label: 'Keywords',
      value: 'keywords',
      desc: (
        <>
          <div className="w-[400px] sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-1 pb-8">
            <Typography className="text-xl font-medium text-lstnGray-900 mb-3">
              Keywords to Monitor
            </Typography>
            <div className="space-y-4 w-[400px] md:w-[880px] h-auto mt-1 pb-8">
              <Typography className="text-sm font-normal text-lstnBlueGray-900">
                Add below the list of keywords you want WellShared to monitor.
              </Typography>
              {keywordChip?.length >= allowedKeywords ? (
                <CustomTooltip
                  content="Upgrade to add more keywords"
                  className="bg-lstnBlueGray-400"
                >
                  <div
                    className="relative flex w-full max-w-[18rem]"
                    style={{ marginLeft: '0px', margin: '1rem 0px' }}
                  >
                    <Input
                      placeholder="Type keyword"
                      type="text"
                      value={keyword}
                      className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                      onChange={onChange}
                      labelProps={{
                        className: 'hidden',
                      }}
                      disabled={keywordLoading}
                      crossOrigin={undefined}
                    />
                    <Button
                      placeholder={undefined}
                      loading={keywordLoading}
                      variant="filled"
                      disabled
                      size="sm"
                      color={keyword ? 'gray' : 'blue-gray'}
                      className="!absolute right-1 top-1 rounded bg-well_primary-50 text-well_primary-500"
                    >
                      ADD
                    </Button>
                  </div>
                </CustomTooltip>
              ) : (
                <div
                  className="relative flex w-full max-w-[30rem]"
                  style={{ marginLeft: '0px', margin: '1rem 0px' }}
                >
                  <Input
                    placeholder="Type keyword"
                    type="text"
                    value={keyword}
                    className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                    onChange={onChange}
                    labelProps={{
                      className: 'hidden',
                    }}
                    disabled={keywordLoading}
                    crossOrigin={undefined}
                  />
                  <Button
                    placeholder={undefined}
                    onClick={addKeyword}
                    loading={keywordLoading}
                    disabled={keyword.length < 2}
                    variant="filled"
                    size="sm"
                    color={keyword ? 'gray' : 'blue-gray'}
                    className="!absolute right-1 top-1 rounded bg-well_primary-50 text-well_primary-500"
                  >
                    ADD
                  </Button>
                </div>
              )}
              {currentProject?.suggestedKeywords?.length > 0 ? (
                <div className="flex flex-wrap gap-0">
                  <Typography className="text-sm font-bold text-lstnBlueGray-900 py-1">
                    Suggestions by WellShared:
                  </Typography>
                  {currentProject?.suggestedKeywords?.map(
                    (item: string, index: number) => {
                      return (
                        <Button
                          key={index}
                          className="text-well_primary-500 w-fit capitalize text-sm font-normal px-2 py-1"
                          variant="text"
                          placeholder={'suggestedKeyword'}
                          onClick={() =>
                            setKeyword(capitalizeFirstLetterOfEachWord(item))
                          }
                        >
                          {item}
                        </Button>
                      )
                    },
                  )}
                </div>
              ) : null}
              <div className="my-2 flex gap-2">
                {keywordChip?.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      open={true}
                      animate={{
                        mount: { y: 0 },
                        unmount: { y: 50 },
                      }}
                      size="sm"
                      className="bg-well_primary-500 text-white w-fit capitalize text-xs font-bold px-2 py-1"
                      value={item}
                      onClose={() => handleClose(item)}
                    />
                  )
                })}
              </div>
              <Typography className="text-sm font-normal text-lstnBlueGray-900 mx-0 my-2">
                Currently using:{' '}
                <span className="font-semibold">
                  {keywordChip?.length} of {allowedKeywords}
                </span>{' '}
                keywords allowed in your plan.{' '}
                <span
                  onClick={handleUpgrade}
                  className="text-well_primary-500 hover:cursor-pointer"
                >
                  Upgrade
                </span>{' '}
                for more keywords.
              </Typography>
            </div>
          </div>
          <div className="w-[400px] sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-1 pb-8">
            <Typography className="text-xl font-medium text-lstnGray-900 mb-3">
              Negative Keywords
            </Typography>
            <div className="space-y-4 w-[400px] md:w-[880px] h-auto mt-1 pb-8">
              <Typography className="text-sm font-normal text-lstnBlueGray-900">
                Add below the list of keywords you want WellShared to exclude.
                <br />
                If these keywords are present in any of the mentions, the
                mention will be discarded.
              </Typography>
              <div
                className="relative flex w-full max-w-[18rem]"
                style={{ marginLeft: '0px', margin: '1rem 0px' }}
              >
                <Input
                  placeholder="Type keyword"
                  type="text"
                  value={negativeKeywords}
                  className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                  onChange={onChangeNegativeKeywords}
                  labelProps={{
                    className: 'hidden',
                  }}
                  crossOrigin={undefined}
                />
                <Button
                  placeholder={undefined}
                  variant="filled"
                  onClick={() => handleAddNegativeKeyword(negativeKeywords)}
                  disabled={negativeKeywords.length < 3}
                  loading={negativeLoading}
                  size="sm"
                  color={keyword ? 'gray' : 'blue-gray'}
                  className="!absolute right-1 top-1 rounded bg-well_primary-50 text-well_primary-500"
                >
                  ADD
                </Button>
              </div>
              <div className="my-2 flex gap-2">
                {negativeKeywordsChip.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      open={true}
                      animate={{
                        mount: { y: 0 },
                        unmount: { y: 50 },
                      }}
                      size="sm"
                      className="bg-well_primary-500 text-white w-fit capitalize text-xs font-bold px-2 py-1"
                      value={item}
                      onClose={() => handleCloseNegative(item)}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </>
      ),
    },
    // {
    //   label: 'Settings',
    //   value: 'settings',
    //   desc: (
    //     <>
    //       <div className="w-[400px] md:w-[936px] h-auto mx-auto mt-1">
    //         <Typography className="text-base font-normal text-lstnGray-900 mb-3">
    //           coming soon
    //         </Typography>
    //       </div>
    //     </>
    //   ),
    // },
  ]

  if (loading === 'idle') {
    return <SplashScreen />
  }

  return (
    <div className="flex flex-col mt-6 md:container md:mx-auto mx-8 py-4 px-8 sm:overflow-x-scroll md:overflow-hidden">
      <div className="w-[400px] md:w-9/12">
        <Typography className="text-2xl font-medium text-lstnGray-900 my-5">
          Social Replies
        </Typography>
      </div>
      <SocialTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data}
      />
    </div>
  )
}
export default SocialReplies
